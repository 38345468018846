<template>
  <div class="recharge-wrap">
    <div class="bread-wrap">
      <span class="bread-title">发票管理/申请发票</span>
    </div>
    <div class="content">
      <div class="info">
        <div class="info-title">发票金额</div>
      </div>
      <div class="info">
        <el-form
          class="info-content"
          :model="numberValidateForm"
          ref="numberValidateForm"
        >
          <el-form-item
            class="info-content"
            label="开票金额："
            prop="invoiceamount" 
            :rules="[{ required: true, message: '开票金额不能为空' }]"
          >
            <el-input-number
              v-model="numberValidateForm.invoiceamount"
              style="width: 200px"
              autocomplete="off"
              :placeholder="canInvoice"
              :min="0"
              :controls="false"
              :max="canInvoiceamount"
              :precision="2"
              clearable
            />&nbsp;&nbsp;元
          </el-form-item>
        </el-form>
      </div>
      <div class="info">
        <div class="info-content">发票内容：</div>
        <div class="info-content">
          <el-radio v-model="radio" label="1">软件服务费</el-radio>
        </div>
      </div>
      <div class="info">
        <div class="info-content">发票备注（选填）：</div>
        <div class="info-content">
          <el-input
            v-model="invoiceremarks"
            placeholder="发票备注将作为补充信息显示在您所开具的发票内。"
            style="width: 360px"
            clearable
          />
        </div>
      </div>
      <div class="info">
        <div class="info-content">留言（选填）：</div>
        <div class="info-content">
          <el-input
            v-model="message"
            placeholder="若您有其他需求，请留言告知我们"
            style="width: 390px"
            clearable
          />
        </div>
      </div>
    </div>
    <div class="content2">
      <div class="info">
        <span class="info-title">发票信息</span>
        <el-button
          size="mini"
          type="primary"
          class="addInvoice"
          @click="ifshowcreate"
          >新增发票信息</el-button
        >
      </div>
      <com-table
        class="grouplist"
        :headercellstyle="headercellstyle"
        :columns="invoicedetaillist2"
        tableheight="300px"
        :data="tabledata"
        @selected="selected"
        @selectall="selectall"
        ref="comTable"
      >
        <el-table-column
          align="center"
          header-align="center"
          slot="action"
          fixed="right"
          label="操作"
          min-width="150"
        >
          <template slot-scope="scope">
            <!-- <el-button
              @click="editegroup(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >详情</el-button
            > -->
            <el-button
              @click="deletegroup(scope.row)"
              class="deletebtn"
              size="mini"
              type="primary"
              >删除</el-button
            >
            <el-button
              v-if="!scope.row.Default"
              @click="setdefaultgroup(scope.row)"
              class="resetbtn"
              size="mini"
              type="primary"
              >设为默认</el-button
            >
            <span v-else class="resetbtn" size="mini">
              &nbsp;&nbsp;&nbsp;默认</span
            >
          </template>
        </el-table-column>
      </com-table>
    </div>
    <!-- <div class="content3">
      <div class="info">
        <div class="info-title">留言（选填）</div>
      </div>
      <div class="info">
        <div class="info-content">
          <el-input v-model='message' placeholder='若您有其他需求，请留言告知我们' style='width: 350px' clearable />   
        </div>
      </div>
    </div> -->

    <div class="submit">
      <el-button 
        @click="submit" 
        class="submitClass" 
        size="100%" 
        type="primary"
        :loading="submitLoading"
        >{{ submitLoading ? '提交中...' : '确认提交' }}</el-button>
    </div>
    <add-invoice-detail
      title="新增发票信息"
      name=""
      canceltext="取消"
      confirmtext="确定"
      :showcreate="showcreate"
      @closecreate="closecreate"
    />
  </div>
</template>
<script>
import navigation from "@/utils/navigation";
import { invoicedetaillist2, addressdetaillist } from "@/utils/tableList";
import ComTable from "@/components/Table";
import AddInvoiceDetail from "../InvoiceDetail/Models/AddInvoiceDetail";
import AddAddressDetail from "../InvoiceDetail/AddressDetail/Models/AddAddressDetail";

export default {
  data() {
    return {
      invoicedetaillist2,
      addressdetaillist,
      tabledata: [],
      invoiceremarks: "",
      message: "",
      amount: "",
      invoicetype: "paper",
      pagination: {
        current: 1,
        size: 20,
        total: 1,
      },
      headercellstyle: {
        background: "rgba(228, 229, 241)",
        color: "rgba(102,102,102)",
      },
      showcreate: false,
      showdelete: false,
      showcreate1: false,
      showdelete1: false,
      radio: "1",
      isPaper: false,
      role: "",
      radioRow: {},
      canInvoice: "",
      canInvoiceamount: 0,
      selection: [],
      invoiceInfoId: "",
      numberValidateForm: {
        invoiceamount: undefined,
      },
      submitLoading: false,
    };
  },
  methods: {
    getinvoicelist() {
      this.tabledata = [];
      this.$store.dispatch("user/listinvoiceinfo").then((res) => {
        console.log("invoice2 list: " + JSON.stringify(res));
        if (res.RetCode == 0) {
          // this.tabledata = res.InvoiceInfoList;
          for (let item of res.InvoiceInfoList) {
            if (item.Email.length != 0) {
              this.tabledata.push(item);
            }
            if (item.Default) {
              let eltb = this.$refs.comTable.$refs.myTable;
              this.timmer = setInterval(() => {
                //  eltb.toggleRowSelection(item, true)
                //  this.invoiceInfoId = item.Id;
                clearInterval(this.timmer);
              }, 1);
            }
          }
        }
      });
    },

    getinvoiceamount() {
      this.$store.dispatch("user/getinvoiceamountinfo").then((res) => {
        if (res.RetCode == 0) {
          if (res.CanInvoice >= 0) {
            this.canInvoice =
              "可开金额" + (res.CanInvoice / 100.0).toFixed(2) + "元";
            this.canInvoiceamount = parseFloat(
              (res.CanInvoice / 100.0).toFixed(2)
            );
          }
        }
      });
    },
    deletegroup(row) {
      let payload = {
        Id: row.Id,
      };
      this.$store
        .dispatch("user/deleteinvoiceinfo", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("删除成功");
            this.getinvoicelist();
          }
        });
    },
    setdefaultgroup(row) {
      let payload = {
        Id: row.Id,
      };
      this.$store
        .dispatch("user/setdefaultinvoiceinfo", { ...payload })
        .then((res) => {
          if (res.RetCode == 0) {
            this.$message.success("设置成功");
            this.getinvoicelist();
          }
        });
    },

    ifshowcreate() {
      this.showcreate = !this.showcreate;
    },
    closecreate() {
      this.getinvoicelist();
      this.showcreate = !this.showcreate;
    },
    submit() {
      if (this.submitLoading) return;
      if (this.numberValidateForm.invoiceamount == undefined) {
        this.$message({
          type: "error",
          message: "金额不能为空！",
        });
        return;
      }
      if (this.numberValidateForm.invoiceamount == 0) {
        this.$message({
          type: "error",
          message: "金额不能为0！",
        });
        return;
      }
      if (this.numberValidateForm.invoiceamount > this.canInvoiceamount) {
        this.$message({
          type: "error",
          message: "开票金额不能超过可开金额",
        });
        return;
      }
      if (this.invoiceInfoId.length == 0) {
        this.$message({
          type: "error",
          message: "请选择发票信息",
        });
        return;
      }

      this.submitLoading = true;
      let data = {
        InvoiceInfoId: parseInt(this.invoiceInfoId),
        Amount: parseFloat(
          (this.numberValidateForm.invoiceamount * 100.0).toFixed(2)
        ),
        TaxType: "软件服务费",
        Remark: this.invoiceremarks,
        Message: this.message,
      };
      this.$store.dispatch("user/applyinvoiceinfo", { ...data }).then((res) => {
        this.submitLoading = false;
        if (res.RetCode == 0) {
          this.$message.success("提交成功！");
          this.$router.go(-1);
        }
      }).catch(() => {
        this.submitLoading = false;
      });
    },

    selected(selection, row) {
      this.invoiceInfoId = row.Id;
      let len = selection.length;
      // if(len > 1) { // 单个就清空所有，选中当前。
      let eltb = this.$refs.comTable.$refs.myTable;
      eltb.clearSelection();
      eltb.toggleRowSelection(row, true);
      // }
    },
    selectall(selection) {
      let eltb = this.$refs.comTable.$refs.myTable;
      eltb.clearSelection();
      this.invoiceInfoId = "";
    },
  },
  mounted() {
    this.getinvoicelist();
    this.getinvoiceamount();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let counter = 0;
      vm.checking = true;
      vm.fullPath = from.name;
      console.log("route name: " + vm.fullPath);
      if (vm.fullPath == "invoice") {
        // let timer = setInterval(() => {
        //   counter++;
        //   if (counter > 1) {
        //     clearInterval(timer);
        //   }
        vm.getinvoicelist();
        vm.getinvoiceamount();

        // }, 1000)
      }
    });
  },
  components: {
    ComTable,
    AddInvoiceDetail,
    AddAddressDetail,
  },
  watch: {
    invoicetype: function (val) {
      if (val == "paper") {
        this.isPaper = false;
      } else {
        this.isPaper = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>